/*
	FICHIER DE COMPILATION VERS LOGIN.CSS
	Author: Nico Blandel | @NicoBlandel
	Author URI: www.nicoblandel.fr
*/

@import "variables/colors",
        "variables/structure",
        "variables/transitions";

body.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);

  .screen-reader-text {
    display: none;
  }
  #login {
    padding: 10px 0 !important;

    h1 {
      a {
        display: block;
        background: none;
        text-indent: 0;
        width: auto;
        height: auto;
        font-size: 2rem;
        font-weight: 700;
        color: #FFF;
        pointer-events: none;

        span {
          display: block;
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }

    form {
      border-radius: 10px;
    }
  }
  .forgetmenot {
    float: none;
  }
  #wp-submit {
    background: var(--color-primary);
    box-shadow: $box_shadow_mini;
    border-radius: 23.5px;
    color: #fff !important;
    padding: 11px 22px !important;
    font-weight: 500;
    font-size: 14px;
    min-width: 100px;
    max-width: 100%;
    margin-top: 5px;
    text-align: center;
    border: none;
    transition: $transition_delay $transition_bezier;
    line-height: initial;
    justify-content: center;
    cursor: pointer;

    &:focus,
    &:hover {
      background: var(--color-primary);
      box-shadow: $box_shadow_maxi;
    }
  }
  #nav {
    color: #FFF;
    text-align: center;

    a {
      color: #FFF;

      &:hover, &:focus {
        color: #FFF;
        text-decoration: underline;
      }
    }
  }
  #backtoblog,
  .privacy-policy-page-link {
    display: none;
  }
  .language-switcher {
    display: none;
  }
  .message,
  .notice,
  .success {
    border-radius: 10px;
  }
}
